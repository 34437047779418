export const apiKey = "AIzaSyC2fHx76NE9he5MI-vJI9uSDCvRSjbwHMU";

export const authDomain = "akular-devel-temp.firebaseapp.com";

export const databaseURL = "https://akular-devel-temp-default-rtdb.firebaseio.com";

export const projectId = "akular-devel-temp";

export const storageBucket = "akular-devel-temp.appspot.com";

export const messagingSenderId = "693054266547";

export const appId = "1:693054266547:web:0eda6088e2243af1c764c6";

export const measurementId = "G-3C9F36YV3H";

export const hostingUrl = "https://akular-devel-temp.web.app";

export const env = "devel";

export const google = {
  apiKey: "AIzaSyAArS98KGveRsYLfdGODlxNdPGxo8g3xro",
  language: "en",
};

export const cloudMessagingKey = "BPmr33dRDua0v8JUbjb1pCv_VcVo2E23UFuKTr2ELPOf6FHd-Q-Bepcv7b_t3J2xsXnxI3LIjHrDiI2X3shWqaI";

export const gAnalyticsTrackingId = "256638789";

export const gTagManagerId = "GTM-M7T2ZZR";

export const functionsRegion = "us-central1";

export const emulators = {
  auth: "9099",
  functions: "5001",
  database: "9000",
  hosting: "5000",
  pubsub: "8085",
};

export const pixyzGWorkerNames = {
  0: "localDevel01",
  1: "localDevel02",
  2: "awsServerDevel01",
};

export default { apiKey, authDomain, databaseURL, projectId, storageBucket, messagingSenderId, appId, measurementId, hostingUrl, env, google, cloudMessagingKey, gAnalyticsTrackingId, gTagManagerId, functionsRegion, emulators, pixyzGWorkerNames };
